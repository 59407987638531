<template>
  <div id='registration-screen' ref="registrationScreen">
    <header>
      <h1 class="title">{{ $t('pages.registration.title') }}</h1>
      <h4 class="subtitle">{{ $t('pages.registration.subtitle') }}</h4>
    </header>
    <div class="content-row">
      <div class="container webshop-container">
        <div class="content-wrapper">
          <mobile-user-auth-tabs />

          <h5 class="form-title" :class="{ 'sm-margin-bottom' : errorToast.isVisible }">{{ $t('pages.registration.form.title') }}</h5>
          <error-toast
            :isVisible="errorToast.isVisible"
            :text="errorToast.text"
          />

          <section class="form-section">
            <h1 class="title">{{ $t(personalSection.sectionTitle) }}</h1>
            <div class="inputs-wrapper">
              <base-input
                v-for="(input, i) in personalSection.inputs" :key="input.key"
                :labelText="$t(input.label)"
                :hasError="input.hasError"
                :type="input.type ? input.type : 'text'"
                @input="handleInputs('personal', i, ...arguments)"
              />
            </div>
          </section>
          <section class="form-section">
            <h1 class="title">{{ $t(invoiceSection.sectionTitle) }}</h1>
            <div class="checkboxes-wrapper">
              <base-checkbox
                v-for="(checkbox, i) in invoiceSection.checkboxes" :key="i"
                :label="$t(checkbox.label)"
                :isChecked="checkbox.isChecked"
                @changed="handleCheckboxInput('invoice', i, ...arguments)"
              />
            </div>
            <div class="inputs-wrapper">
              <template v-for="(input, i) in invoiceSection.inputs" >
                <base-input
                  v-if="isCompanyNameInputVisible(i) && !input.options && isTaxNumberInputVisible(input.key)"
                  :labelText="$t(input.label)"
                  :hasError="input.hasError"
                  :key="input.key"
                  :type="input.type ? input.type : 'text'"
                  @input="handleInputs('invoice', i, ...arguments)"
                />
                <base-selector
                  v-else-if="input.options"
                  :options="input.options"
                  :key="input.key"
                  :labelText="$t(input.label)"
                  :hasError="input.hasError"
                  :defaultSelected="input.options[countryHungaryIndex]"
                  @selected="handleCountrySelect"
                />
              </template>
            </div>
          </section>
          <section class="form-section">
            <h1 class="title">{{ $t(passwordSection.sectionTitle) }}</h1>
            <div class="inputs-wrapper">
              <base-input
                v-for="(input, i) in passwordSection.inputs" :key="input.key"
                :labelText="$t(input.label)"
                :hasError="input.hasError"
                :type="input.type ? input.type : 'text'"
                @input="handleInputs('password', i, ...arguments)"
              />
            </div>
          </section>
          <section class="policies-section">
            <base-checkbox
              :label="$t(policiesSection.label)"
              :isChecked="policiesSection.isChecked"
              :hasError="policiesSection.hasError"
              @changed="handleCheckboxInput('policies', 0, ...arguments)"
            />
          </section>
          <section class="buttons-section">
            <base-button
              :isLoading="isBtnLoading"
              :text="$t('pages.registration.buttons.registration')"
              @clicked="handleRegistrationBtnClick"
            />
            <span class="already-member-text">{{ $t('pages.registration.alreadyMember') }}</span>
            <base-button
              :isSecondary="true"
              :text="$t('pages.registration.buttons.login')"
              @clicked="handleLoginBtnClick"
            />
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import scroll from '@/components/shared/mixins/scroll';
import validations from '@/components/shared/mixins/validations';

import BaseInput from '@/components/shared/elements/inputs/BaseInput';
import BaseButton from '@/components/shared/elements/buttons/ButtonBase';
import BaseCheckbox from '@/components/shared/elements/inputs/BaseCheckbox';
import BaseSelector from '@/components/shared/elements/inputs/BaseSelector';

export default {
  name: 'RegistrationScreen',
  mixins: [scroll, validations],
  props: {},
  components: {
    BaseInput,
    BaseButton,
    BaseCheckbox,
    BaseSelector,
    'error-toast': () => import('@/components/shared/elements/toasts/ErrorToast'),
    'mobile-user-auth-tabs': () => import('@/components/shared/elements/partials/MobileUserAuthTabs'),
  },
  data: () => ({
    personalSection: {
      sectionTitle: 'pages.registration.form.sections.personal.title',
      inputs: [
        {
          key: 'firstName',
          label: 'pages.registration.form.sections.personal.labels.firstName',
          value: undefined,
          hasError: false,
        },
        {
          key: 'lastName',
          label: 'pages.registration.form.sections.personal.labels.lastName',
          value: undefined,
          hasError: false,
        },
        {
          key: 'email',
          type: 'email',
          label: 'pages.registration.form.sections.personal.labels.email',
          value: undefined,
          hasError: false,
        },
        {
          key: 'phoneNumber',
          label: 'pages.registration.form.sections.personal.labels.phoneNumber',
          value: '',
          hasError: false,
        },
      ],
    },
    invoiceSection: {
      sectionTitle: 'pages.registration.form.sections.invoiceData.title',
      checkboxes: [
        {
          label: 'pages.registration.form.sections.invoiceData.checkboxes.personal',
          isChecked: true,
        },
        {
          label: 'pages.registration.form.sections.invoiceData.checkboxes.company',
          isChecked: false,
        },
      ],
      inputs: [
        {
          key: 'companyName',
          required: true,
          label: 'pages.registration.form.sections.invoiceData.labels.companyName',
          value: '',
          hasError: false,
        },
        {
          key: 'taxNumber',
          required: true,
          label: 'pages.registration.form.sections.invoiceData.labels.taxNumber',
          value: undefined,
          hasError: false,
        },
        {
          key: 'country',
          required: true,
          label: 'pages.registration.form.sections.invoiceData.labels.country',
          value: undefined,
          options: undefined,
          hasError: false,
        },
        {
          key: 'city',
          required: true,
          label: 'pages.registration.form.sections.invoiceData.labels.city',
          value: undefined,
          hasError: false,
        },
        {
          key: 'zipCode',
          required: true,
          label: 'pages.registration.form.sections.invoiceData.labels.zipCode',
          value: undefined,
          hasError: false,
        },
        {
          key: 'publicSpaceName',
          required: true,
          label: 'pages.registration.form.sections.invoiceData.labels.publicSpaceName',
          value: undefined,
          hasError: false,
        },
        {
          key: 'publicSpaceType',
          required: false,
          label: 'pages.registration.form.sections.invoiceData.labels.publicSpaceType',
          value: '',
          hasError: false,
        },
        {
          key: 'houseNumber',
          required: false,
          label: 'pages.registration.form.sections.invoiceData.labels.houseNumber',
          value: '',
          hasError: false,
        },
        {
          key: 'building',
          required: false,
          label: 'pages.registration.form.sections.invoiceData.labels.building',
          value: '',
          hasError: false,
        },
        {
          key: 'stairway',
          required: false,
          label: 'pages.registration.form.sections.invoiceData.labels.stairway',
          value: '',
          hasError: false,
        },
        {
          key: 'floor',
          required: false,
          label: 'pages.registration.form.sections.invoiceData.labels.floor',
          value: '',
          hasError: false,
        },
        {
          key: 'door',
          required: false,
          label: 'pages.registration.form.sections.invoiceData.labels.door',
          value: '',
          hasError: false,
        },
      ],
    },
    passwordSection: {
      sectionTitle: 'pages.registration.form.sections.passwords.title',
      inputs: [
        {
          key: 'password',
          type: 'password',
          label: 'pages.registration.form.sections.passwords.labels.password',
          value: undefined,
          hasError: false,
        },
        {
          key: 'passwordAgain',
          type: 'password',
          label: 'pages.registration.form.sections.passwords.labels.passwordAgain',
          value: undefined,
          hasError: false,
        },
      ],
    },
    policiesSection: {
      label: 'pages.registration.form.policies',
      isChecked: false,
      hasError: false,
    },
    errorToast: {
      isVisible: false,
      text: undefined,
    },
    isBtnLoading: false,
    hasIncorrectValue: {
      personalSection: [],
      invoiceSection: [],
    },
    countryHungaryIndex: undefined,
  }),
  created() {
    this.getCountries().then((resp) => {
      const countryIndex = this.invoiceSection.inputs.findIndex((item) => item.key === 'country');

      this.invoiceSection.inputs[countryIndex].options = resp.data.map((item) => item.name);
      this.countryHungaryIndex = resp.data.findIndex((el) => el.alpha2 === 'hu');
    });

    document.addEventListener('keyup', this.handleKeyPress);
  },
  computed: {
    ...mapGetters({
      getCurrentCart: 'cart/getCurrentCart',
    }),
  },
  methods: {
    ...mapActions({
      registerUser: 'users/register',
      getCountries: 'settings/getCountries',
    }),
    isCompanyNameInputVisible(index) {
      if (index === 0 && this.invoiceSection.checkboxes[1].isChecked) {
        return true;
      } else if (index === 0) {
        return false;
      }

      return true;
    },
    isTaxNumberInputVisible(key) {
      if (key === 'taxNumber' && this.invoiceSection.checkboxes[1].isChecked) {
        return true;
      } else if (key === 'taxNumber') {
        return false;
      }

      return true;
    },
    handleInputs(sectionKey, index, value) {
      switch (sectionKey) {
        case 'personal':
          this.personalSection.inputs[index].value = value;
          break;
        case 'invoice':
          this.invoiceSection.inputs[index].value = value;
          break;
        case 'password':
          this.passwordSection.inputs[index].value = value;
          break;
        default:
          break;
      }
    },
    handleCheckboxInput(section, index, value) {
      switch (section) {
        case 'invoice':
          if (value) {
            // eslint-disable-next-line no-param-reassign
            this.invoiceSection.checkboxes.forEach((item) => { item.isChecked = false; });
            this.invoiceSection.checkboxes[index].isChecked = value;

            // reset company name field + tax number
            this.invoiceSection.inputs.filter((item) => item.key === 'companyName')[0].value = '';
            this.invoiceSection.inputs.filter((item) => item.key === 'companyName')[0].hasError = false;
            this.invoiceSection.inputs.filter((item) => item.key === 'taxNumber')[0].value = '';
            this.invoiceSection.inputs.filter((item) => item.key === 'taxNumber')[0].hasError = false;
          }
          break;
        case 'policies':
          this.policiesSection.isChecked = value;
          break;
        default:
          break;
      }
    },
    handleCountrySelect(payload) {
      const countryIndex = this.invoiceSection.inputs.findIndex((item) => item.key === 'country');

      this.invoiceSection.inputs[countryIndex].value = payload;
    },
    validateForm() {
      this.hasIncorrectValue.personalSection = false;
      this.hasIncorrectValue.invoiceSection = false;

      this.personalSection.inputs.forEach((input, i) => {
        if (input.key !== 'phoneNumber' && (input.value === undefined || input.value.length === 0)) {
          this.personalSection.inputs[i].hasError = true;
          this.hasIncorrectValue.personalSection = false;
        } else if (input.key === 'phoneNumber' && input.value !== '') {
          this.personalSection.inputs[i].hasError = !this.validatePhoneNumber(input.value);
          this.hasIncorrectValue.personalSection = this.hasIncorrectValue.personalSection ? true : this.personalSection.inputs[i].hasError;
        } else if (input.key === 'firstName' || input.key === 'lastName') {
          this.personalSection.inputs[i].hasError = !this.validateName(input.value);
          this.hasIncorrectValue.personalSection = this.hasIncorrectValue.personalSection ? true : this.personalSection.inputs[i].hasError;
        } else {
          this.personalSection.inputs[i].hasError = false;
        }
      });

      this.invoiceSection.inputs.forEach((input, i) => {
        if ((i === 0 || input.key === 'taxNumber') && this.invoiceSection.checkboxes[1].isChecked) {
          if (input.value === undefined || input.value.length === 0) {
            this.invoiceSection.inputs[i].hasError = true;
          } else {
            this.invoiceSection.inputs[i].hasError = false;
          }
        } else if ((i === 0 || input.key === 'taxNumber') && !this.invoiceSection.checkboxes[1].isChecked) {
          this.invoiceSection.inputs[i].hasError = false;
        }

        if ((i !== 0 && input.key !== 'taxNumber') && input.required) {
          if (input.value === undefined || input.value.length === 0) {
            this.invoiceSection.inputs[i].hasError = true;
          } else if (input.key === 'city' || input.key === 'publicSpaceName') {
            this.invoiceSection.inputs[i].hasError = !this.validateStringInput(input.value);
            this.hasIncorrectValue.invoiceSection = this.hasIncorrectValue.invoiceSection ? true : this.invoiceSection.inputs[i].hasError;
          } else {
            this.invoiceSection.inputs[i].hasError = false;
          }
        }
      });

      this.passwordSection.inputs.forEach((input, i) => {
        if (input.value === undefined || input.value.length === 0) {
          this.passwordSection.inputs[i].hasError = true;
        } else {
          this.passwordSection.inputs[i].hasError = false;
        }
      });

      if (!this.policiesSection.isChecked) {
        this.policiesSection.hasError = true;
      } else {
        this.policiesSection.hasError = false;
      }

      const personalSectionHasError = this.personalSection.inputs.filter((input) => input.hasError).length > 0;
      const invoiceSectionHasError = this.invoiceSection.inputs.filter((input) => input.hasError).length > 0;
      const passwordSectionHasError = this.passwordSection.inputs.filter((input) => input.hasError).length > 0;

      if (personalSectionHasError || invoiceSectionHasError || passwordSectionHasError || !this.policiesSection.isChecked) {
        this.errorToast.isVisible = true;
        this.errorToast.text = this.hasIncorrectValue.personalSection || this.hasIncorrectValue.invoiceSection
          ? this.$t('general.errors.incorrectValues') : this.$t('general.errors.missingFields');
      } else {
        this.errorToast.isVisible = false;
        this.errorToast.text = undefined;
      }

      return !personalSectionHasError && !invoiceSectionHasError && !passwordSectionHasError && this.policiesSection.isChecked;
    },
    validatePassword() {
      const password = this.passwordSection.inputs[0];
      const passwordAgain = this.passwordSection.inputs[1];

      if (password.value !== passwordAgain.value) {
        this.passwordSection.inputs.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.hasError = true;
        });
        this.errorToast.isVisible = true;
        this.errorToast.text = this.$t('general.errors.password');

        return false;
      }

      this.passwordSection.inputs.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.hasError = false;
      });
      this.errorToast.isVisible = false;
      this.errorToast.text = undefined;

      return true;
    },
    validateTaxNumberLocale() {
      const taxNumberField = this.invoiceSection.inputs.filter((item) => item.key === 'taxNumber')[0];
      const validationResult = this.validateTaxNumber(taxNumberField.value);

      if (!validationResult) {
        taxNumberField.hasError = true;
        this.errorToast.isVisible = true;
        this.errorToast.text = this.$t('general.errors.taxNumber');
      }

      return validationResult;
    },
    validateZipCodeLocale() {
      const zipCodeField = this.invoiceSection.inputs.find((item) => item.key === 'zipCode');
      const validationResult = this.validateZipCode(zipCodeField.value);

      if (!validationResult) {
        zipCodeField.hasError = true;
        this.errorToast.isVisible = true;
        this.errorToast.text = this.$t('general.errors.incorrectValues');
      }

      return validationResult;
    },
    parseRequestObject() {
      return {
        firstName: this.personalSection.inputs.filter((item) => item.key === 'firstName')[0].value,
        lastName: this.personalSection.inputs.filter((item) => item.key === 'lastName')[0].value,
        email: this.personalSection.inputs.filter((item) => item.key === 'email')[0].value,
        phoneNumber: this.personalSection.inputs.filter((item) => item.key === 'phoneNumber')[0].value,
        password: this.passwordSection.inputs.filter((item) => item.key === 'password')[0].value,
        invoiceData: {
          isCompany: this.invoiceSection.checkboxes[1].isChecked,
          companyName: this.invoiceSection.inputs.filter((item) => item.key === 'companyName')[0].value,
          taxNumber: this.invoiceSection.inputs.filter((item) => item.key === 'taxNumber')[0].value,
          country: this.invoiceSection.inputs.filter((item) => item.key === 'country')[0].value,
          cityName: this.invoiceSection.inputs.filter((item) => item.key === 'city')[0].value,
          zipCode: this.invoiceSection.inputs.filter((item) => item.key === 'zipCode')[0].value,
          publicSpaceName: this.invoiceSection.inputs.filter((item) => item.key === 'publicSpaceName')[0].value,
          publicSpaceType: this.invoiceSection.inputs.filter((item) => item.key === 'publicSpaceType')[0].value,
          houseNumber: this.invoiceSection.inputs.filter((item) => item.key === 'houseNumber')[0].value,
          building: this.invoiceSection.inputs.filter((item) => item.key === 'building')[0].value,
          stairwayNumber: this.invoiceSection.inputs.filter((item) => item.key === 'stairway')[0].value,
          floor: this.invoiceSection.inputs.filter((item) => item.key === 'floor')[0].value,
          door: this.invoiceSection.inputs.filter((item) => item.key === 'door')[0].value,
        },
      };
    },
    handleRegistrationBtnClick() {
      const validationResult = this.validateForm();

      if (validationResult) {
        const passwordValidationResult = this.validatePassword();

        if (passwordValidationResult) {
          const taxNumberValidationResult = this.invoiceSection.checkboxes[1].isChecked
            ? this.validateTaxNumberLocale()
            : true;

          const zipCodeValidationResult = this.validateZipCodeLocale();


          if (taxNumberValidationResult && zipCodeValidationResult) {
            const requestObj = this.parseRequestObject();

            if (this.getCurrentCart !== undefined) {
              requestObj.cart = this.getCurrentCart._id;
            }

            this.isBtnLoading = true;
            this.registerUser(requestObj).then(() => {
              this.isBtnLoading = false;
              this.$router.push('/');
            }).catch((error) => {
              this.isBtnLoading = false;
              this.errorToast.isVisible = true;
              this.errorToast.text = error.data.message;

              this.scrollToToast('registrationScreen');
            });
          } else {
            this.scrollToToast('registrationScreen');
          }
        } else {
          this.scrollToToast('registrationScreen');
        }
      } else {
        this.scrollToToast('registrationScreen');
      }
    },
    handleLoginBtnClick() {
      this.$router.push('/users/login');
    },
    handleKeyPress(e) {
      if (e.key === 'Enter') {
        this.handleRegistrationBtnClick();
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyPress);
  },
};
</script>

<style lang='scss' scoped>
$formWidth: 933px;
$bgImageHeight: 795px;
$sectionsWidth: 430px;
$errorToastWidth: 310px;
$buttonsWidth: 210px;

@keyframes fadeInDown {
  0% {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
  }
  100% {
    overflow: hidden;
    max-height: 150px;
    opacity: 1;
  }
}

.error-toast {
  max-width: $errorToastWidth;
  margin: 0 auto 14px;
}

.error-toast-transition-enter-active {
  animation: fadeInDown .8s;
}

.error-toast-transition-leave-active {
  animation: fadeInDown .8s reverse;
}

#registration-screen {
  padding: 30px 0 122px;
}

.mobile-user-auth-tabs {
  display: none;

  @media screen and (max-width: $breakpointDownXs) {
    display: block;
  }
}

header {
  text-align: center;

  @media screen and (max-width: $breakpointDownXs) {
    display: none;
  }
}

.title {
  font-size: 40px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  color: $primaryBlue;
}

.subtitle {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  margin: 0;
}

.content-row {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 0;
    z-index: -1;
    width: 100%;
    height: $bgImageHeight;
    background-image: url('/static/images/backgrounds/webshop-registration-bg.jpg');
    background-size: cover;
    background-position: center;

    @media screen and (max-width: $breakpointDownXs) {
      display: none;
    }
  }
}

.content-wrapper {
  position: relative;
  z-index: z('page-body');
  max-width: $formWidth;
  margin: 25px auto 0;
  padding: 18px 70px 25px;
  background-color: $white;
  border-radius: $globalBorderRadius;
  box-shadow: $registrationContentShadow;

  @media screen and (max-width: $breakpointDownSm) {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (max-width: $breakpointDownXs) {
    padding: 0 40px 40px;
  }
}

.form-title {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin: 0 0 35px;

  &.sm-margin-bottom {
    margin: 0 0 10px;
  }

  @media screen and (max-width: $breakpointDownXs) {
    margin: 23px 0;
    font-size: 14px;
    line-height: 17px;
  }
}

section {
  max-width: $sectionsWidth;
  margin: 0 auto 17px;

  @media screen and (max-width: $breakpointDownXs) {
    max-width: none;
  }

  .title {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    color: $globalFontColor;
    margin: 0 0 8px;

    @media screen and (max-width: $breakpointDownXs) {
      font-size: 20px;
      margin: 0 0 20px;
    }
  }
}

.inputs-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;

  @media screen and (max-width: $breakpointDownXs) {
    grid-template-columns: 1fr;
    column-gap: 0;
  }
}

.checkboxes-wrapper {
  display: flex;
  margin: 0 0 10px 5px;

  .base-checkbox {
    &:first-child {
      margin: 0 10px 0 0;
    }
  }
}

.policies-section {
  max-width: $sectionsWidth;
  margin: 40px auto 20px;

  @media screen and (max-width: $breakpointDownXs) {
    margin: 37px auto 33px;
  }
}

.already-member-text {
  font-size: 12px;
  font-weight: 500;
  color: $black;
  display: block;
  text-align: center;
  margin: 10px 0;
}

.buttons-section {
  width: $buttonsWidth;

  @media screen and (max-width: $breakpointDownXs) {
    width: 100%;
  }

  button {
    display: block;
    width: 100%;
    text-transform: uppercase;

    @media screen and (max-width: $breakpointDownXs) {
      line-height: 45px;
    }
  }
}
</style>
